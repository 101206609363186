import { Row, Col} from 'react-bootstrap';
import './settings_menu.css'
import check_mark from './assets/check_mark.svg'
import { useState } from 'react';



function SettingsMenu(props){

    const [breakTime , setBreakTime] = useState(props.breakTime)

    const handleBreakTimeChange = () => {
        props.handleBreakTimeChange(breakTime)
    }

    const sendToFeedback = () => {
        window.open("https://forms.gle/f9Ugd1qBH1H4mgfC6", "_blank")
    }

    return (
        <div className="settings_content">
            <Row className="settings_content_row"xs={2}>
                <Col xs={9}>
                    <p className='break-time'>BREAK TIME</p>
                </Col>
                <Col xs={3}>
                    <form>
                        <input autoFocus className='break-time-input' type="text" value={breakTime} onInput={time => setBreakTime(time.target.value)}></input>
                    </form>
                </Col>
            </Row>
            <Row className="settings_content_row" xs={2}>
                <Col xs={9}>
                    <button className='feedback-button' onClick={sendToFeedback}>
                        FEEDBACK
                    </button>
                </Col>
                <Col xs={3}>
                    <button className='save-button' onClick={handleBreakTimeChange}>
                        <img className="save-button-img" src={check_mark}alt='reset button'></img>
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default SettingsMenu