import React from 'react';
import { Row } from 'react-bootstrap';
import {useSpring , animated} from 'react-spring'
import './CarAnimation.css';
import black_car from './assets/black_car.svg';

function Animation(props) {
    
    const [timerWidth, setTimerWidth] = React.useState(0);
    const [carWidth , setCarWidth] = React.useState(0);
    const [hours , setHours] = React.useState(0);
    const [minutes , setMinutes] = React.useState(0);
    const [seconds , setSeconds] = React.useState(0);
    const ref = React.useRef(null);
    const carref = React.useRef(null);
    
    const handlresize = () => {
        setTimerWidth(ref.current.offsetWidth);
        setCarWidth(carref.current.clientWidth);
        // console.log("timer width: " + ref.current.offsetWidth + "car width: " + carref.current.clientWidth); 
    }


    React.useEffect(() => {
        setTimerWidth(ref.current.offsetWidth);
        setCarWidth(carref.current.clientWidth);
        window.addEventListener('resize', handlresize);
        // console.log("timer width: " + ref.current.offsetWidth + "car width: " + carref.current.clientWidth);
    }, [  ]);


    React.useEffect(() => {
        // convert timeremaining to minutes and seconds
        if(props.timeRemaining > 0){
            const _hours = Math.floor(props.timeRemaining / 3600);
            var time_remaining = props.timeRemaining % 3600;
            const _minutes = Math.floor(time_remaining / 60);
            time_remaining = time_remaining % 60;
            const _seconds = time_remaining;
            setHours(_hours);
            setMinutes(_minutes);
            setSeconds(_seconds);
        }
        else if(props.timeRemaining === 0){ //if timer was running but the reset button is pressed
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        }
    }
    , [props.timeRemaining]);


    const animationProps = useSpring(
        {left: props.onBreak ? ((props.isRunning) ? 10 : (timerWidth-carWidth)) :  ((props.isRunning) ? (timerWidth - (carWidth + 10)) : 10),
        config: { duration: props.totalTime*1000},
        });

    const carLoad = () => {
        setCarWidth(carref.current.clientWidth);
        // console.log("car width: " + carref.current.clientWidth);
    }
    
    return (
        <div ref={ref}  className="timerAnimation">
            <Row>
                <p className="clockStartCount">00:00:00</p>
                <p className="clockEndCount">{("0"+hours).slice(-2)}:{("0"+minutes).slice(-2)}:{("0"+seconds).slice(-2)}</p>
            </Row>
            <animated.img onLoad={carLoad}  ref={carref} src={black_car} className="black_car" alt="black_car" style={{left: animationProps.left}} />
        </div>
   
    
    );
}

export default Animation;