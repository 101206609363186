import React, { useEffect } from "react";
import Webcam from "react-webcam";
import './Camera.css';
import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';

function Camera(props){
    let webcam; 
    const webcamRef = React.useRef(null);
    const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
    const detectorConfig = {
    runtime: 'tfjs',
    };

    let faceDetector = null;

    if(props.activateCamera === true){
        webcam = <Webcam  ref={webcamRef} className="webcam"></Webcam>
    }else{
        webcam = null;
    }
    const capture = ( () => {
        if(faceDetector == null){
            faceDetection.createDetector(model , detectorConfig).then((detectorObj) => {
                if(detectorObj === null){
                    console.log("could not create object")
                }else{
                    faceDetector = detectorObj
                    let image = webcamRef.current.getCanvas(); 
                    estimateFaces(image);
                }
            })
        }else{
            let image = webcamRef.current.getCanvas(); 
            estimateFaces(image);
        }
    })

    const estimateFaces = ( (image) => {
        if(faceDetector !== null && image !== null){
            faceDetector.estimateFaces(image).then((estimatedFaces)=> {
                if(estimatedFaces.length > 0){
                    props.handleCameraEvent(true)
                }else{
                    props.handleCameraEvent(false)
                }
            })
        }
    })

    useEffect(() => {
        if(props.activateCamera === true){
            const interval = setInterval(() => {
                capture();
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }  //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.activateCamera]);


    return (
       webcam
    )
}

export default Camera;