
import React from "react";
import './SwitchButton.css';

function SwitchButton(props) {
    const [isOn, setIsOn] = React.useState(false);

    function handleClick() {
        props.onSwitch(!isOn);
        setIsOn(!isOn);
    }


    return (
        <label className="switch">
        <input type="checkbox" onClick={handleClick}/>
        <span className="slider round"></span>
      </label>
    );

}

export default SwitchButton;