import './App.css';
import { Container, Row, Col} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Timer from './Timer';
import SwitchButton from './SwitchButton';
import settings from "./assets/settings_button.svg"
import { useSpring, animated } from "react-spring";
import SettingsMenu from './settings_menu';

function App() {

  const [timerStyle] = React.useState("SUNSET DRIVE");
  const [activateCamera, setActivateCamera] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [breakTime , setBreakTime] = React.useState(5)

  function changeCameraStatus(status){
    setActivateCamera(status);
  }

  const { filter } = useSpring({
    from: {
      filter: 'blur(0px)'
    },
    config: {duration: 500},
    loop: { reverse: false }
  });
 
  const { transform } = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    config: { duration: 500 },
    loop: { reverse: true }
  });


  const handleSettingsClick = () => {
    settingsClickAnimation()
    canvasBlurAnimation()
    setShow(true)
  }

  const settingsClickAnimation = () => {
    transform.start({
      from: {
        transform: "rotate(0deg)",
      },to:{
        transform: "rotate(180deg)",
      }
    });
  }

  const canvasBlurAnimation = () => {
    filter.start({
      from : {
        filter: "blur(0px)",
      }, to: {
        filter: "blur(10px)",
      }
    }) 
  }

  const handleModalDismiss = () => {
    setShow(false)
    transform.start({
      from: {
        transform: "rotate(180deg)",
      },to:{
        transform: "rotate(0deg)",
      }
    })

    filter.start({
      from : {
        filter: "blur(10px)",
      }, to: {
        filter: "blur(0px)",
      }
    }) 
  }

  const handleBreakTimeChange = (newBreakTime) => {
    setBreakTime(newBreakTime)
    setShow(false)
    handleModalDismiss()
  }






  return (
    <div>
    <Modal
      className="settings_menu"
      size="md"
      show={show}
      onHide={() => handleModalDismiss()}
    >
      <Modal.Body>
        <SettingsMenu breakTime={breakTime} handleBreakTimeChange={handleBreakTimeChange}/>
      </Modal.Body>
    </Modal>

   <Container >
      <animated.div style={{filter}}>
            <Row>
            <Col>
            </Col>
              <Col  className='justify-content-center'>
                <p className='title'>TIMEY</p>
                <p className='subtitle'>{timerStyle}</p>
                <SwitchButton onSwitch={changeCameraStatus}></SwitchButton>
              </Col>
              <Col className='d-flex justify-content-end settings'>
                  <button className="playPause">
                      <animated.div onClick={handleSettingsClick} style={{ transform }}>
                        <img id="settings_button" src={settings} className="playPauseIcon" alt="settings icon"></img>  
                      </animated.div>                
                  </button>
              </Col>
            </Row>
      <Timer activateCamera={activateCamera} breakTime={breakTime}/>
      </animated.div>
    </Container>
    </div>
  );
}

export default App;
